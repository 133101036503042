<template>
  <b-row>
    <b-col>
      <h1 class="text-capitalize mb-2">
        please choose an entity
      </h1>
    </b-col>

    <b-col cols="12">
      <b-card>
        <select-entity-type />

        <b-row
          v-if="$store.getters['mainEntity/getEntityType']"
        >
          <b-col md="9">
            <entity-select />
          </b-col>

          <b-col
            v-if="$can('manage','adminDashboard')"
            md="3"
            class="text-center text-md-left mt-0 mt-sm-2"
          >
            <b-button
              :disabled="!$store.getters['mainEntity/getEntityId']"
              variant="primary"
              class="btn-block"
              :to="{name:'inpo-dashboard'}"
            >
              Organization Dashboard
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import EntitySelect from '@/common/components/common/FormInputs/userEntitySelect.vue'
import SelectEntityType from '@/common/components/Entities/SelectEntityType.vue'

export default {
  name: 'Home',
  components: {
    EntitySelect,
    SelectEntityType,
  },
}
</script>

<style lang="scss">

</style>
