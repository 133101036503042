<template>
  <div>
    <custom-vue-select
      id="entity-select"
      label="Select Entity"
      :rules="rules"
      text-field="name"
      placeholder="No entity selected"
      :options="entities"
      :selected.sync="entityProxy"
    />
  </div>
</template>

<script>
import applyPermissions from '@/common/compositions/common/applyUserPermissionsInEntity'
import CustomVueSelect from './CustomVueSelect.vue'

export default {
  name: 'UserEntitySelect',
  components: {
    CustomVueSelect,
  },
  props: {
    rules: { type: String, default: '' },
  },
  data() {
    return {
      entities: [],
    }
  },
  computed: {
    entityProxy: {
      get() {
        return this.$store.getters['mainEntity/getEntityId']
      },
      set(id) {
        const entity = this.getEntityById(id)

        this.applyUserPermissionsInEntity(id)

        this.changeMainEntityInStore(entity)
        this.saveMainEntityToLocalStorage(entity)
      },
    },
    entityType() {
      return this.$store.getters['mainEntity/getEntityType']
    },
    entityTypeLookup() {
      return {
        'Islamic Org': 1,
        Business: 2,
        'Event-Organization': 3,
      }
    },
  },
  watch: {
    entityType(newType) {
      if (newType) this.getEntities()
    },
  },
  setup() {
    const { applyUserPermissionsInEntity } = applyPermissions()
    return {
      applyUserPermissionsInEntity,
    }
  },
  created() {
    this.getEntities()
  },
  methods: {
    getEntities() {
      this.$portalUsers.get('v1/user-entities', {
        params: {
          type: this.entityTypeLookup[this.entityType],
          status: true,
        },
      }).then(res => {
        this.entities = res.data.data
        this.setEntityToFirstOne()
      })
    },
    setEntityToFirstOne() {
      if (this.isUserHaveOneEntity()) {
        const inpo = this.entities[0]
        this.entityProxy = inpo.id
      }
    },
    isUserHaveOneEntity() {
      return this.entities.length === 1
    },
    getEntityById(id) {
      return this.entities.find(entity => entity.id === id)
    },
    changeMainEntityInStore(inpo) {
      this.$store.commit('mainEntity/setEntityId', inpo.id)
      this.$store.commit('mainEntity/setEntityName', inpo.name)
    },
    saveMainEntityToLocalStorage(inpo) {
      localStorage.setItem('entityId', inpo.id)
      localStorage.setItem('entityName', inpo.name)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
