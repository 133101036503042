import Vue from 'vue'
import userDefaultPermissions from '@/common/default-permissions/userDefaultPermissions'
import store from '@/store'
import { setEncryptedItem, getEncryptedItem } from '@/libs/secure-web-storage'
import userPermissions from '@/common/compositions/permissions/userPermissions'
import permissionsParser from './permissionsParser'

const { getUserPermissionsInEntity } = userPermissions()

export default function applyUserPermissions() {
  const { formatPermissions } = permissionsParser()

  const setPermissions = permissions => {
    Vue.prototype.$ability.update(permissions)

    setEncryptedItem('abilities', permissions)
  }

  const applyUserPermissionsInEntity = entityId => {
    const { username } = store.getters['auth/getUserData']
    const globalPermissions = getEncryptedItem('globalAbilities')

    getUserPermissionsInEntity(username, entityId).then(permissionsGroups => {
      const formattedPermissions = formatPermissions(permissionsGroups)
      const allPermissions = [...new Set([...globalPermissions, ...userDefaultPermissions, ...formattedPermissions])]

      setPermissions(allPermissions)
    }).catch(() => {
      setPermissions([...new Set([...userDefaultPermissions, ...globalPermissions])])
    })
  }

  return {
    applyUserPermissionsInEntity,
  }
}
