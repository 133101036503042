<template>
  <div>
    <b-form-group
      label="Select Entity Type First"
      label-for="entity-type"
    >
      <b-form-radio-group
        id="entity-type"
        v-model="entityTypeProxy"
        :options="options"
      />
    </b-form-group>
  </div>
</template>
<script>

export default {
  name: 'SelectEntityType',
  data() {
    return {
      options: [
        { text: 'Islamic Org', value: 'Islamic Org' },
        { text: 'Business', value: 'Business' },
        { text: 'Event Organizer', value: 'Event-Organization' },
        { text: 'No Entity', value: '' },
      ],
    }
  },
  computed: {
    entityTypeProxy: {
      get() {
        return this.$store.getters['mainEntity/getEntityType']
      },
      set(value) {
        this.resetEntity()

        this.$store.commit('mainEntity/setEntityType', value)
        localStorage.setItem('entityType', value)
      },
    },
  },
  methods: {
    resetEntity() {
      this.$store.commit('mainEntity/setEntityId', '')
      this.$store.commit('mainEntity/setEntityName', '')
      this.$store.commit('mainEntity/setEntityType', '')

      localStorage.removeItem('entityId')
      localStorage.removeItem('entityName')
      localStorage.removeItem('entityType')
    },
  },
}
</script>
<style lang="">

</style>
